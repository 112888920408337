import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/Clients/StephaneCatoire/Gastby/scatoire-perso/src/components/blog-post-layout.jsx";
import { graphql } from "gatsby";
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Simple end of month sales forecast`}</h1>
    <h2>{`Introduction`}</h2>
    <p>{`Let's imagine that you are sitting at your desk, sipping a coffee that you paid CHF 0.70, when your boss come into your office and ask you to produce a report giving the expected end of month sales per BU, based on the sales Month to day.`}</p>
    <p>{`Now forecasting is a very tricky thing to do, taking in account currency fluctuations, raw material price variations, weather changes, seasonality and whatnot.`}</p>
    <p>{`Fortunately, your boss just want to have a broad view of sales so a simple linear regression will do. You sigh in relief because you won't have to dig into R time series forecasting package or figure out how to create a machine learning model.`}</p>
    <p>{`So, you think you can extract a Month to day sales per company and BU from the ERP, open it in excel and extrapolate by applying the simple formula:`}</p>
    <pre><code parentName="pre" {...{}}>{`(Month to date value * Current day of month) / total days in month
`}</code></pre>
    <p>{`Simple enough, but you quiclky notice that the forecasted values are way too small. Off course ! We don't sell anything on weekends and during holidays, so your extrapolation should take that into account.`}</p>
    <p>{`And here comes the headaches.`}</p>
    <h2>{`Working days`}</h2>
    <p>{`OK, not an issue, Excel provides a function to calculate the number of working days between two dates (NETWORKDAYS). You can then add a formula that will calculate the number of working days since begin of month and day of the month, and another one that will calculate the number of working day for the month`}</p>
    <p>{`So lets imagine your worksheet looks like this`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "238px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f296d90430a067697584daf7dbe2f882/5ea4d/SimpleDataStructure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.306748466257666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACbElEQVQoz1WSS08TYRiF57f5D9wbdmo0mkiiCzduNJKIoSJ4ITExIkhKESyitFKYll6gt5npzLTT+wVa2gICpbQQS/uYTnHhm5y853zJ+yVPcoThdz4eTfl58NbPvUkft8bchBI675fD3BgRuTnm5u6El6ERkfsTInouweMPPu6Me7lt8XD9iZNrD1d5sxRmOxVCsFjzjH7JMTqXY3KxyMvZDMFYhenVPM+ms0zY8lhsBV7MZHj60UCOVxi3ZenfWaw5hl/rDD2XmV4pIcoqgriVYGHFg3XJxdeffqzLHj7Nu3B4YtjsG6jZY/TcMfH8CUahgZQaePOtcIqWrGB3+plbWOPV7CJCOl8jJBtElBSSliUkJwnLSfTUDpKaYbfWpPb7guphm+rhObXDNnsHrYE/umBn75htKUlUTmHf3ESoVqvIioIkSah6HFXTkWSF0k6ZdCbL8UmD/nS7PXo9uLzs8qfTodcPwHmriaSoSOEI3z0uhKBSxOmWcYhRXD4Nh+llAnLRzJJRI1VqmrhGsTHYV0qWmmipOg6PgnM9yszqOkJY7X8YYc0jse5TWdtUcIgRtpUCYkBDSlTIlVukS6ekd/5XZveMRKaO0y2xthHh889fCPVaFU3TkGWZuGGgXWHn8gXiuk6nc2mi/UPsdrt0+sgM5qJ9RkzTiUkSK5suBCO7RyCcIBhNElIyBCIG/rCBrBfYihiUqk0q+23K+y3K+2126y12a2dmrhycUywf4Y8YBMNJFt1eBPeWwbxdHNTmh4+FFS9Wu9vEtzsCqNkTEoWGWZW+EvmGKbNGxSZKvGxWbc7mZGr+G38BlOGqQfUG8V8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "  SimpleDataStructure",
            "title": "  SimpleDataStructure",
            "src": "/static/f296d90430a067697584daf7dbe2f882/5ea4d/SimpleDataStructure.png",
            "srcSet": ["/static/f296d90430a067697584daf7dbe2f882/222b7/SimpleDataStructure.png 163w", "/static/f296d90430a067697584daf7dbe2f882/5ea4d/SimpleDataStructure.png 238w"],
            "sizes": "(max-width: 238px) 100vw, 238px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`with columns MTDDate;Country;Sales`}</p>
    <p>{`you will add two columns, one to calculate the number of working days elapsed since beginning of the month, the other to calculate to total number of working days in the month.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-Excel"
      }}>{`=NETWORKDAYS.INTL([@MTDDate],DATE(YEAR([@MTDDate]),MONTH([@MTDDate]),1),1)
=NETWORKDAYS.INTL(DATE(YEAR([@MTDDate]),MONTH([@MTDDate]),1),DATE(YEAR([@MTDDate]),MONTH([@MTDDate])+1,1)-1,1,getHolidayDatesByCountry(Holidays!$A$2:$C$24,[@Country]))
`}</code></pre>
    <p>{`Note that in the example we consider all the weekend days are on Saturday and sunday (the last parameter value of the function).`}</p>
    <h2>{`Regional adaptions`}</h2>
    <p>{`What we haven't taken in account yet in our business day computation, are the holidays. If your companies are located in the same region, like for instance in Europe where the culture is the more or less the same between countries, that will not have much of an impact. The challenge will come when you will need to aggregate differents regions of the world like Asia and Middle East where you can have longer period of holidays than in Europe. So you need to find the way to extract a list of holidays per year and per country.`}</p>
    <p>{`And here starts the trouble. You will find different sources of data giving you the holidays corresponding to each country, but you will need to aggregate them yourself. Unless off courrse if your company is not as stingy as one that bill CHF 0.70 a coffee for its fellow employees, you can buy these kind of information. In my case, I had to go to a site, selecting a country, refresh, extract, copy paste in a worksheet. This process can be very tedious and error prone. Fortunately there is a way around !`}</p>
    <h2>{`Python to the rescue`}</h2>
    <p>{`Each Excel User should have Python installed on his computer. The services it offers in data manipulation are invaluable. Even if it is not (yet) fully integrated in Excel we can already uses its numerous libraries to achieve our goals.`}</p>
    <h3>{`Python packages`}</h3>
    <p>{`We will use the holidays library to extract the holiday dates per year and per country and the pandas libraby that is a must have for anyone interested in data manipulation. I will probably have numerous opportunities to write about Pandas later, but for our today's exercice, we will just concentrate on extracting the holidays. So here we go : in a shell window we will first install the needed packages:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-DOS"
      }}>{`pip install pandas
pip install openpyxl
pip install holidays
`}</code></pre>
    <p>{`Then we will use the following pyhton code :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-Python"
      }}>{`from datetime import time
import pandas as pd
import holidays

allHolidays = []
YEARSLIST = [2022]
COUNTRYLIST = ['FR','BE','CH','DE','CN']

for country in COUNTRYLIST:
    for hol in holidays.CountryHoliday(country,years=YEARSLIST,observed=False).items():
        allHolidays.append({'Country':country, 'Date':hol[0],'Holiday':hol[1]})

allHolidays = pd.DataFrame(allHolidays)
allHolidays.to_excel("myHolidays.xlsx", index=False)

`}</code></pre>
    <p>{`This code will iterate the countries in the COUTRYLIST array, and then append the allHoliday matrix with a dictionary containing the Country, Date and Holidays description extracted from the holidays library.`}</p>
    <p>{`Then it wil be converted into a Pandas DataFrame, that we will use to create an excel packages looking like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "422px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b14f96474b4df4517f6511585ced107f/fa5c1/HolidaysInExcelFile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.86503067484662%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAClUlEQVQ4y31U23LqMAzk//+qr+105gAJsWMnjnMDcgFaQkO4bUcCcoDSZkbj2JZXq5XswWw2w2KxwGq1YquqClmWYT6fY7lc9kbrZVmyTadT5GR5jjyfoq7r/vyADn5+fuLr6wubzYYXCbAoCqzXazRNw0ZByaqyRJqm7BPHMWwcc8C2bRljYIzhDRqTJEGWpghDAxtZntMejVcQ/k9SUGY0J5a0R35BEGCgtYaUAq+vr3h7e0NV1xgP/2HiCZRlxal+fHywEbv393e8vLwgTTNeI3bEnAIwQ60VPM/DbDZnLYjhaDiEJwTi2CK2ltOKrEWzbliS+WzGcnRdh+1226dL30BKD2EY4nA88QIBCik57SgyUEqxHMZEPG+7jv1OpxOOx2Nv+/0eh8MBA1960IHGdtvxBgESY6U0a0LBlFbM1HUcrJuG/ejwre12uzOgFB4CAux2HDVLE07X2gihMdBKMSj5UJBms2G/XwGFNzkzvAH0fR9JbLl6JjTwlY/IGEghsG42lO/vgMqXDwyvKfuIogjWWvhKw5gQk4mLLM9/gD0w9B4YngF9X7GGlLrWl5HS9n0URfkj7R7Qm7hPAbkgxpz10wpRnPSHrhV9CuiMR9BBcA8oBAKtufdMGHJhqJWo954V5D5lqnL4HzBNE9bPXq5jZCIOSLeBvmctcwfoOmM+0N1UWfqSG9lEBlJK1IsFtm3LDOngn31IgKRZt9vfaUja0dWjqxmEIdIkgQ7CnumfGpJmV8A8O2sYBhpJmsGXki9+2277+/onw9FoCMd10e0uGiYxHMfh5lZaM1MhBFzXxXA0xnK54kfhEfTm6gl+42hCTnVdIb282PN5gaoqUZQFs6SXOrYxvy7Xal+BqZXo/xupHpNoJMbaFQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "HolidaysInExcelFile",
            "title": "HolidaysInExcelFile",
            "src": "/static/b14f96474b4df4517f6511585ced107f/fa5c1/HolidaysInExcelFile.png",
            "srcSet": ["/static/b14f96474b4df4517f6511585ced107f/222b7/HolidaysInExcelFile.png 163w", "/static/b14f96474b4df4517f6511585ced107f/ff46a/HolidaysInExcelFile.png 325w", "/static/b14f96474b4df4517f6511585ced107f/fa5c1/HolidaysInExcelFile.png 422w"],
            "sizes": "(max-width: 422px) 100vw, 422px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We will then copy the worksheet into our main Excel workbook so that we can refer to it.`}</p>
    <h2>{`Updating the Networkdays formula`}</h2>
    <p>{`What we need to do now is to update the networkdays formula with the holidays parameter. In the formulas I showed earlier, I did not use the holidays parameter. This parameter allows you to use a range of dates that will be considered as holidays dates. If you have Office 365, you can use the FILTER formula that return a range with filter criteria applied. I do not have Office 365, so I wrote the following vba function instead that will return a vector of dates related to the country passed as a parameter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-vba"
      }}>{`Function getHolidayDatesByCountry(rng As Range, Country As String) As Variant
Dim returnValue() As Variant
    ix = 0
    ReDim Preserve returnValue(0)
    For Each r In rng.Rows
        If r.Cells(1) = Country Then
            ' we exclude weekends from list of holidays since we
            ' have already excluded them from our workday formula
            If Application.WorksheetFunction.Weekday(r.Cells(2), 2) < 6 Then
                returnValue(ix) = r.Cells(2).Value
                ix = ix + 1
                ReDim Preserve returnValue(ix)
            End If
        End If
    Next

    getHolidayDatesByCountry = returnValue

End Function
`}</code></pre>
    <p>{`Which will lead to the adapted formulas for the computation of working days:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-Excel"
      }}>{`=NETWORKDAYS.INTL(DATE(YEAR([@MTDDate]),MONTH([@MTDDate]),1),[@MTDDate],1,getHolidayDatesByCountry(Holidays!$A$2:$C$53,[@Country]))
=NETWORKDAYS.INTL(DATE(YEAR([@MTDDate]),MONTH([@MTDDate]),1),DATE(YEAR([@MTDDate]),MONTH([@MTDDate])+1,1)-1,1,getHolidayDatesByCountry(Holidays!$A$2:$C$24,[@Country]))
`}</code></pre>
    <p>{`Et voilà ! Your Estimated sales formula will be:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-Excel"
      }}>{`=[@Sales]/[@[Working days to date]]*[@[Total working days in month]]
`}</code></pre>
    <p>{`Just paste down the formula and you will get your end of month sales estimate.`}</p>
    {
      /* Footer to allow translation to be copied on each post
          Boring but wainting for a better solution */
    }



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      